html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* img {
  pointer-events: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#homeHeader {
  background-image: url("./images/homeHeader.svg");
  background-size: cover;
  background-position: bottom;
}

#dotSection {
  background-image: url("./images/dots.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

#worldMapSection {
  background-image: url("./images/worldMap.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

#curvySection {
  background-image: url("./images/curvyImage.svg");
  /* background-size: contain; */
  background-size: cover;
  /* background-position: bottom; */
}

#slider::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  #worldMapSection {
    background: none;
  }
}
